import {
  Box,
  Button,
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Input,
  Select,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";

import React, { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import { CheckIcon } from "@chakra-ui/icons";
import { useLocation, useNavigate } from "react-router-dom";

function QuickEnquiry() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const [validateCaptcha, setvalidateCaptcha] = useState(false);
  const [userIpAddress, setUserip] = useState("");

  const form = useRef();
  const [loading, setloading] = useState(false);

  function onChange(value) {
    setvalidateCaptcha(true);
  }
  // Collect Website URL
  const websiteUrl = window.location.href;

  // Collect Device Information
  const deviceInfo = navigator.userAgent;

  // Collect Browser Information
  const browserInfo = navigator.userAgent;

  let account, service, template;

  if (location.pathname == "/adventure-resorts-in-ramanagara") {
    account = "-vfqWA6DFVqfcSV7i";
    service = "service_4ey0hds";
    template = "template_yfgf2gh";
  } else if (location.pathname == "/day-outing-resorts-in-ramanagara") {
    account = "-vfqWA6DFVqfcSV7i";
    service = "service_4ey0hds";
    template = "template_yfgf2gh";
  } else if (location.pathname == "/riverside-resorts-in-sakleshpur") {
    account = "Pub_7blKWB0-b2oOR";
    service = "service_39m5lre";
    template = "template_968a6fc";
  } else if (location.pathname == "/Bangalore-Adventure-resorts") {
    account = "stqnW0CBRdz3hewL7";
    service = "service_wowdv6l";
    template = "template_xy01d9h";
  } else if (location.pathname == "/day-outing-resorts-in-bangalore") {
    account = "stqnW0CBRdz3hewL7";
    service = "service_wowdv6l";
    template = "template_xy01d9h";
  } else if (location.pathname == "/Adventure-Resorts-in-Kanakapura") {
    account = "0OuHhb_1NcwO20lcW";
    service = "service_sxsbw3b";
    template = "template_xlopsoa";
  } else if (location.pathname == "/day-outing-resorts-in-kanakapura") {
    account = "0OuHhb_1NcwO20lcW";
    service = "service_sxsbw3b";
    template = "template_xlopsoa";
  } else if (location.pathname == "/estatestay-in-chikmagalur") {
    account = "hXFj8UAQWdrBrZlgN";
    service = "service_mki3th9";
    template = "template_rtqr3p9";
  } else if (location.pathname == "/hilltop-homestay-in-kudremukh") {
    account = "lGN4qnPsQFwqjgfxg";
    service = "service_r1ndy18";
    template = "template_uw9fmjd";
  } else if (location.pathname == "/estate-homestay-in-chikmagalur") {
    account = "I1YOcH3d3VnEUYnnb";
    service = "service_se56d1l";
    template = "template_t8zifv9";
  } else if (location.pathname == "/luxury-resorts-in-hassan") {
    account = "L6r08mhdJJFwvDWsW";
    service = "service_r6vrmkt";
    template = "template_ezy4b5n";
  } else if (location.pathname == "/resorts-near-sakleshpur") {
    account = "wmWkUmaQJbvcOQYNc";
    service = "service_4b65nid";
    template = "template_2osczmu";
  } else if (
    location.pathname == "/cruise-packages-from-bangalore" ||
    location.pathname == "/cordelia-cruise-packages" ||
    location.pathname == "/international-cruise-trips" ||
    location.pathname == "/cruise-holiday-packages" ||
    location.pathname == "/luxurious-cruise-vacations"
  ) {
    account = "jWcxhaQqsZgQ0HT1p";
    service = "service_xve012r";
    template = "template_yiwtji8";
  } else if ((location.pathname == "/vvistara-landing-page")) {
    account = "mR8Kt60tSo2Fa9Y-L";
    service = "service_uoym7b5";
    template = "template_oq7paeq";
  } else {
    account = "_811xbPUyirnb26qf";
    service = "service_24j4a4v";
    template = "template_znnl8pd";
  }
  const getUserIpAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      console.log(data.ip, "data");
      setUserip(data?.ip);
    } catch (error) {
      console.error("Error getting IP address:", error);
      return "Unknown IP";
    }
  };

  useEffect(() => {
    getUserIpAddress();
  }, []);
  const sendEmail = (e) => {
    e.preventDefault();
    setloading(true);
    const emailParams = {
      userIpAddress: userIpAddress,
      // other parameters you want to include in the email
    };
    emailjs
      .sendForm(
        `${service}`,
        `${template}`,
        form.current,
        `${account}`,
        emailParams
      )

      .then(
        (result) => {
          console.log(result.text);
          sessionStorage.setItem("thankyou", true);

          setloading(false);
          form.current.reset();
          onClose();
          navigate("/thank-you");
        },
        (error) => {
          toast({
            title: "Something went wrong",
            status: "error",
            isClosable: true,
            position: "top",
          });
          setloading(false);
          onClose();
          form.current.reset();
        }
      );
  };

  return (
    <div>
      <Box
        position={"fixed"}
        cursor={"pointer"}
        right={"0"}
        bottom={"30%"}
        zIndex={"1000"}
        style={{ writingMode: "vertical-lr" }}
        onClick={onOpen}
      >
        <Text
          pl={"10px"}
          bg={"green"}
          borderRadius={"10px 0px 0px 10px"}
          fontWeight={600}
          color={"white"}
          fontSize={"18px"}
          p={"20px 5px"}
        >
          {" "}
          Quick Enquiry{" "}
        </Text>
      </Box>
      <Drawer
        placement={"right"}
        onClose={onClose}
        isOpen={isOpen}
        size={["md", "xs"]}
        borderRadius={"25px"}
      >
        <DrawerOverlay />
        <DrawerContent
          bg={"#117873"}
          //   backgroundImage={'url("/Images/black_scratch_bg.png")'}
          h={"max-content"}
          w={["90%", "40%"]}
          m="auto"
          borderRadius={"10px"}
          mb={"0px"}
          //  border={"2px solid white"}
        >
          <DrawerCloseButton color={"white"} size={"lg"} fontWeight={700} />
          <Box color={"white"}>
            <Box
              p="15px"
              justifyContent={"space-around"}
              // backgroundImage="url('/Images/brownbg.webp')"
              // backgroundSize="cover"
              // backgroundPosition="center"
              // backgroundRepeat="no-repeat"
              //      backgroundImage="linear-gradient(to right, #5a3f37, #2c7744)"
              width="100%"
            >
              <Text
                textAlign="center"
                style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                fontSize="25px"
                fontWeight={"bold"}
              >
                Let's Connect
              </Text>
              {/* <Text textAlign={"center"}>
                {" "}
                Please leave us your contact details and our team will get back
                to you within 24 hours. Your information is safe with us.
              </Text> */}
              <form ref={form} onSubmit={sendEmail}>
                <Box p={"7px"} display="block">
                  <label>Name</label>
                  <Input
                    type="text"
                    color={"black"}
                    bg={"#faf9f5"}
                    name="name"
                    id="name"
                    required
                  />
                </Box>
                <Box p={"10px"}>
                  <label htmlFor="number">Mobile Number</label>
                  <Input
                    type="tel"
                    color={"black"}
                    bg={"white"}
                    minLength={10}
                    maxLength={10}
                    name="number"
                    id="number"
                    required
                  />
                </Box>
                <Box p={"7px"}>
                  <label htmlFor="groupsize">Group Size</label>
                  <Input
                    name="groupsize"
                    bg={"white"}
                    color="black"
                    id="groupsize"
                    type="tel"
                    required
                    maxLength={3}
                  />
                </Box>
                <Box p={"7px"}>
                  <label htmlFor="Category">Category</label>
                  <Select
                    id="Category"
                    name="Category"
                    bg="white"
                    color={"black"}
                  >
                    <option value="Day out">Day out</option>
                    <option value="Stay">Stay</option>
                  </Select>
                </Box>
                {/* <Box p={"7px"}>
                  <label htmlFor="Subject">Subject</label>
                  <Input
                    name="Subject"
                    color={"black"}
                    bg={"white"}
                    id="Subject"
                  />
                </Box> */}
                <input
                  id="userIpAddress"
                  name="userIpAddress"
                  value={userIpAddress}
                  style={{ visibility: "hidden" }}
                  p={"0px"}
                  m="0"
                  fontSize={"1px"}
                />

                <Box w={"100%"} m="auto">
                  <ReCAPTCHA
                    sitekey="6LduLesoAAAAADULb7axOKkC_d0AICLeb-CS_yNc"
                    onChange={onChange}
                  />
                </Box>
                <Button
                  display={"block"}
                  width={"max-content"}
                  m="auto"
                  type="submit"
                  mt={"20px"}
                  color="white"
                  bg={"#FAA318"}
                  fontWeight={700}
                  fontSize={["19px", "20px"]}
                  style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
                  isLoading={loading}
                  _hover={{ bg: "#306b29" }}
                  isDisabled={validateCaptcha ? false : true}
                >
                  {" "}
                  Submit
                </Button>
              </form>
            </Box>
          </Box>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default QuickEnquiry;
