import { Box, Flex, Img, keyframes } from "@chakra-ui/react";
import { Suspense, lazy } from "react";
import QuickEnquiry from "./Components/QuickEnquiry";
import "./App.css"
import { Helmet } from "react-helmet";
const AllRoutes = lazy(() => import("./Components/AllRoutes"));
const Navbar = lazy(() => import("./Components/Navbar"));
const Footer = lazy(() => import("./Components/Footer"));
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;
function App() {
  const renderLoader = () => (
    <Flex minH={"100vh"} align="center" justify="center">
      <Box position="relative">
        {/* Logo in the center */}
        <Box
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Img src="/Images/logo.webp" alt="Logo" width="65px" height="60px" />
        </Box>
        <Box
          border="16px solid #f3f3f3"
          borderRadius="50%"
          borderTop="16px solid #074A48"
          width="120px"
          height="120px"
          animation={`${spin} 0.5s linear infinite`}
          margin="auto"
        ></Box>{" "}
      </Box>
    </Flex>
  );
  return (
    <Box fontFamily={"Poppins"}>
    
      <Suspense fallback={renderLoader()}>
        <QuickEnquiry />
        <Navbar />
        <AllRoutes />
        <Footer />
      </Suspense>
    
    </Box>
  );
}

export default App;
